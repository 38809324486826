body {
  margin: 0;
  font: 1em/1.3 "Helvetica Neue", Helvetica, Arial, "Open Sans", sans-serif;
  font-weight: 400;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: HelveticaNeueLTStd-Blk, "Helvetica Neue", Helvetica, Arial,
    "Open Sans", sans-serif;
  font-weight: 900;
}

strong {
  font-weight: 400 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input::-ms-clear {
  display: none;
}
